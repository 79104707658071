import React from 'react';
import Helmet from "react-helmet"
import googleLogo from '../assets/google_logo.png';
import iitrLogo from '../assets/iit_roorkee_logo.jpg';

const Hero = () => {
    return (
        <div className="bg-white py-24 px-4 sm:px-6 lg:px-8">
            <div className="container mx-auto max-w-4xl">
                <h1 className="text-4xl md:text-5xl font-bold mb-8 leading-tight">
                    Slash Slippage Costs, Maximize Profits,<br /> and{' '}
                    <span className="text-blue-600">Supercharge Your Trading</span>
                </h1>

                <p className="text-base mb-10 leading-relaxed">
                    Ever struggled to place a limit order, only to watch prices shift before you can act? It's a common frustration that often forces traders to resort to costlier market orders.
                    At TradingEdge, we've developed an AI-powered solution to this problem. Our technology predicts short-term price movements, allowing us to place your limit orders with precision timing. The result? Your orders are filled quickly, typically at prices better than market orders.
                </p>

                <p className="text-base mb-10">
                This means you get the best of both worlds: the speed of market orders with the cost savings of limit orders. It's not just about saving money—it's about giving you a real edge in today's fast-paced markets.
                Try TradingEdge and experience the difference smart order placement can make to your trading
                </p>
                <div id="getWaitlistContainer" data-waitlist_id="18901" data-widget_type="WIDGET_2"></div>
                <Helmet>
                    <link rel="stylesheet" type="text/css" href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css" />
                    <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js" async></script>
                </Helmet>

                <div className="mt-20 flex flex-col items-center">
                    <h2 className="text-base mb-6">Where we are from</h2>
                    <div className="flex items-center space-x-8">
                        <div className="flex items-center">
                            <img
                                src={googleLogo}
                                alt="Google"
                                className="h-24 mr-2"
                            />
                        </div>
                        <div className="flex items-center">
                            <img
                                src={iitrLogo}
                                alt="IIT Roorkee"
                                className="h-24 mr-2"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;