import React from 'react';

const Navbar = () => {
    return (
        <nav className="bg-white p-4">
            <div className="container mx-auto flex justify-between items-center">
                <div className="text-2xl font-bold">
                    <span className="text-black">Trading</span>
                    <span className="text-blue-600">Edge</span>
                </div>
                {/* <div className="space-x-4">
                    <a href="#" className="text-gray-600 hover:text-gray-900">Analyze</a>
                    <a href="#" className="text-gray-600 hover:text-gray-900">Live Trade</a>
                    <a href="#" className="text-gray-600 hover:text-gray-900">Option Chain</a>
                    <a href="#" className="text-gray-600 hover:text-gray-900">TradingView</a>
                    <button className="bg-blue-600 text-white px-4 py-2 rounded">Login</button>
                    <button className="bg-blue-600 text-white px-4 py-2 rounded">SignUp</button>
                </div> */}
            </div>
        </nav>
    );
};

export default Navbar;