import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Stats from '../components/Stats';
import ProductFeatures from '../components/ProductFeatures';
import CTA from '../components/Cta';
import Footer from '../components/Footer';

const LandingPage = () => {
  return (
    <div className="font-sans">
      <Navbar />
      <Hero />
      {/* <Stats /> */}
      {/* <ProductFeatures /> */}
      {/* <CTA /> */}
      {/* <Footer /> */}
    </div>
    // <div className="min-h-screen bg-gray-100">
    //   {/* Header */}
    //   <header className="bg-white shadow-sm">
    //     <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    //       <div className="flex justify-between h-16">
    //         <div className="flex">
    //           <div className="flex-shrink-0 flex items-center">
    //             <img className="h-8 w-auto" src="/logo.svg" alt="Logo" />
    //           </div>
    //           <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
    //             <a href="#" className="border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
    //               Home
    //             </a>
    //             <a href="#" className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
    //               Features
    //             </a>
    //             <a href="#" className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
    //               Pricing
    //             </a>
    //           </div>
    //         </div>
    //         <div className="hidden sm:ml-6 sm:flex sm:items-center">
    //           <button className="bg-indigo-600 text-white px-4 py-2 rounded-md text-sm font-medium">Sign up</button>
    //         </div>
    //       </div>
    //     </nav>
    //   </header>

    //   {/* Hero Section */}
    //   <main>
    //     <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
    //       <div className="px-4 py-6 sm:px-0">
    //         <div className="text-center">
    //           <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
    //             <span className="block">Welcome to Our App</span>
    //             <span className="block text-blue-600">It's Nice to Meet You</span>
    //           </h1>
    //           <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
    //             Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi facere, soluta magnam consectetur molestias itaque ad sint fugit architecto incidunt iste culpa perspiciatis possimus voluptates aliquid consequuntur cumque quasi. Perspiciatis.
    //           </p>
    //           <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
    //             <div className="rounded-md shadow">
    //               <a href="#" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10">
    //                 Get started
    //               </a>
    //             </div>
    //             <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
    //               <a href="#" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10">
    //                 Learn more
    //               </a>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </main>

    //   {/* Footer */}
    //   <footer className="bg-white">
    //     <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
    //       <div className="flex justify-center space-x-6 md:order-2">
    //         <a href="#" className="text-gray-400 hover:text-gray-500">
    //           <span className="sr-only">Facebook</span>
    //           <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
    //             <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
    //           </svg>
    //         </a>
    //         <a href="#" className="text-gray-400 hover:text-gray-500">
    //           <span className="sr-only">Twitter</span>
    //           <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
    //             <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
    //           </svg>
    //         </a>
    //         <a href="#" className="text-gray-400 hover:text-gray-500">
    //           <span className="sr-only">GitHub</span>
    //           <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
    //             <path fillRule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clipRule="evenodd" />
    //           </svg>
    //         </a>
    //       </div>
    //       <div className="mt-8 md:mt-0 md:order-1">
    //         <p className="text-center text-base text-gray-400">
    //           &copy; 2024 Your Company, Inc. All rights reserved.
    //         </p>
    //       </div>
    //     </div>
    //   </footer>
    // </div>
  );
};

export default LandingPage;