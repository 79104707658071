import React from 'react';
import LandingPage from './pages/LandingPage';
import AuthPage from './pages/AuthPage';

function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;